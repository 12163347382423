import styled, { css } from 'styled-components';
import { ImageInput } from '../CreateEvent/styles';
import { EventContainer, EventContent, AddCalenderButton, ImageContainer, EventContentContainer, DescriptionContainer, EventDate } from '../MainEvents/styles';
import {Tabs} from "react-bootstrap";

const BoldFont = 'Rubik-Bold';

const InputFieldContainer = styled.div`
  width: ${({UpdateStatus, survey, width}) => UpdateStatus || survey || width ? '100%' : 'calc(100% - 58px)'};
  float: left;
  display: flex;
  background: white;
  -moz-box-shadow: ${({UpdateStatus}) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none' };
  -webkit-box-shadow: ${({UpdateStatus}) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none' };
  box-shadow: ${({UpdateStatus}) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none' };
  height: 'auto';
  border-color: ${({comment, survey}) => comment ? 'rgba(0, 0, 0, 0.09)' : survey ? '#cdcbcb': 'none'};
  border-image: ${({comment}) => comment ? 'initial' : 'none'};
  padding: ${({padding}) => padding ? padding : '15px'};
  border-radius: ${({comment}) => comment ? '0px' : '6px'};
  border-width: ${({comment, survey}) => comment || survey ? '1px' : '0px'};
  border-style: ${({comment, survey}) => comment || survey ? 'solid' : 'none'};
  height: ${({height}) => height};
  flex-direction: ${({flex}) => flex && 'row-reverse'};
  ${({height}) => height && css`
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 6px 6px 0px 0px !important;
  `}

  >div:nth-child(2){
    width: 90%; 
    .
  }
  @media (max-width: 500px) {
    width: ${({UpdateStatus}) => UpdateStatus ? '100%' : 'calc(100% - 50px)'};
  }
  .CustomTextAreaField {
    ${'' /* padding-top: 15px; */}
    line-height: 20px;
    height: 50px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    width: 100%;
    color: #3A3A3A;
    resize: none;
    float: left;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #9c9c9c;
      opacity: 1;
    } 

    :-ms-input-placeholder {
      color: #9c9c9c;
    }

    ::-ms-input-placeholder {
      color: #9c9c9c;
    }  
  }
  
  .CustomTextAreaField2 {
    padding-top: 15px;
    line-height: 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    width: calc(100% - 4px);
    color: #3A3A3A;
    resize: none;
    float: left;
    height: 80px;
    position: absolute;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #9c9c9c;
      opacity: 1;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    :-ms-input-placeholder {
      color: #9c9c9c;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    ::-ms-input-placeholder {
      color: #9c9c9c;
      font-size: 18px;
      font-family: Rubik-Regular;
    }
  }
`;

const EventDateDetails = styled(EventDate)`
  margin-top: 15px;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  > div {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      margin-right: 4px;
      height: 15px;
      margin-top: 0px;
      float: left;
    }
    > svg {
      width: 16px;
      margin-right: 4px;
      height: 15px;
      margin-top: 0px;
      float: left;
    }
  }
  a {
    color: rgb(21,159,202);
    font-family: ${BoldFont};
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%
  }
`;

const EventDetailsContainer = styled(EventContainer)`
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 0;
  display: block;
  height:  auto;
  width: 100%;
  display: flex;
  background: white;
  padding: 20px;

  flex-wrap: ${({ wrap }) => wrap ? 'wrap' : ''};
  background: ${({ background }) => background ? 'white' : 'transparent'};
  padding: ${({ padding }) => padding ? '20px' : '0'};
  border-radius:6px;
  margin-bottom: ${({ updatedlayout }) => updatedlayout ? '20px' : '0'};
  .leftContainer{
    width: ${({ updatedlayout }) => updatedlayout ? '62.4%' : '64%'};
    margin-right: ${({ updatedlayout }) => updatedlayout ? '20px' : '0px'};
    display:block;
  }
  .rightContainer{
    width: ${({ updatedlayout }) => updatedlayout ? 'calc(37.6% - 20px)' : '36%'};
    display:block;
    height:100%;

  }
  @media (max-width: 767px) {
    height: auto;
    display: block;
  }
`;

const EventDetailsContentContainer = styled(EventContentContainer)`
  padding: 5px 27px 14px;
  width: 30%;

  @media (max-width: 530px) {
    padding: 25px 15px 14px;
  }

  @media (max-width: 767px) and (min-width: 530px) {
    height: auto;
  }
`;

const EventDescription = styled(DescriptionContainer)`
  padding: 7px 0 16px 15px;
  height: 100%;
  width: 40%;

  > div {
    height: auto;
    display: inline-block !important;
    width: 100%;
  }

  @media (min-width: 951px) and (max-width: 1010px) {
    padding: 7px 20px 16px 10px;
  }
`;

const EventImageContainer = styled(ImageContainer)`
  display: inline-block;
  height: 484px;;
  width: 100%;
  margin-bottom: ${({margin}) => margin ? margin : '0'};
  @media (max-width: 767px) and (min-width: 530px) {
    height: 100%;
  }
  .eventLabel{
    height:0px;
    width:100%;
    position: relative;
    left: 10px;
    top: 10px;

    .label{
    height:24px;
    width:100px;
    display:flex;
    background:white;
    border-radius:3px;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color:#0D4270;
    position:relative;
    align-items: center;
    justify-content: center;
    top:10px;
    left:10px;
    }
  }
  > img {
    height: 100%;
    cursor: default;
    border-radius: 6px;
  }
`;

const EventDetailsContent = styled(EventContent)`
  > span {
    font-size: 24px;
    font-family: Proxima-Nova-Regular;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    text-overflow: ellipsis;
    line-height: 26px;
    max-height: 52px;
  }

  ${({ isDetailsPage }) => isDetailsPage && css`
    > span {
      text-transform: capitalize;
    }
  `}
`;

const ContentWrapper = styled.div`
  float: left;
  width: 100%;
  background-color: white;
  -moz-box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  -webkit-box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  margin-top: 50px;
  padding: 0 20px;
  display: table;
  height: 135px;
  
  @media (max-width: 650px) {
    padding: 20px;
  }
  
  > div {
    display: table-cell;
    vertical-align: middle;
    
    > span {
      font-size: 18px;
      color: rgb(51,51,51);
      font-family: ${BoldFont};
      float: left;
      line-height: 38px;
    }
    
    > label {
      margin-left: 10%;
      font-size: 16px;

      @media (max-width: 650px) {
        margin-left: 5%;
        width: 95%;
        margin-top: 15px;
      }
    }
    
    > button {
      float: right;
      @media (max-width: 650px) {
        margin-top: 20px;
        float: left;
      }
    }
  }
`;

const CommentsContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: 50px;
`;

const FieldContainer = styled.div`
  padding: ${({ commentsSection }) => commentsSection ? '0px 25px 20px 25px' : '0 25px 10px 25px'};
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;
  background: ${({ background }) => background ? '#F7F5EB' : 'white'};
  margin-bottom:10px;
  padding-bottom:25px;
  position: relative; 
  ${({ noFloat }) => noFloat && css`
    padding: 34px 0 20px 0;
    @media (max-width: 500px) {
      padding: 0;
    }
  `}
  
  > span {
    font-size: 24px;
  }
  
  &:last-child {
    border-bottom: none;
  }
  
  ${(props) => props.fixHeight && css`
    border-bottom: none;
  `}

  ${({ socialPage }) => socialPage && css`
    > span {
      font-size: 18px;
      float: left;
      padding: 20px 0 10px;
    }
  `}

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

const CustomTextArea = styled.textarea`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  background-color: #ffffff;
  border:none;
  width: 80%;
height:60px;
   resize: auto;
   padding:15px;
   padding-top:20px;
resize:none;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/* identical to box height, or 125% */

display: flex;
align-items: center;


  &:active, &:focus {
    outline: none;
  }
  
  @media (max-width: 500px) {
    width: calc(100% - 50px);
    padding: 10px;
  }
`;
const CommentInnerContainer=styled.div`
width: 100%;
position:relative;
background:white;
border: 1px solid rgba(0, 0, 0, 0.09);
border-radius:8px;


`

const CommentContent = styled.div`
 
  width: 100%;
  margin-top: 5px;
  background-color:white;
  display:flex;
  justify-contain:flex-start;
  > img {
    width: 60px !important;
    height: 60px !important;
    margin-right: 20px;
    float: left;
    border-radius: 50%;
    
    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  
  ${(props) => props.isReply && css`
    padding: 0 70px;
  `}

  .ImageDiv {
    position: relative;
    right: -82px;
    top: -52px;
    >img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      float: left;
      border-radius: 50%;
    }
  }

  .InputImageSection {
    float: left;
    left: 80px;
    top: -125px;
    >img {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      float: left;
    }
  }

  .InputImageSpan {
    color: #333339;
  }

  >div:nth-child(2){
    padding: 0 0 0 5px;
    >div{
      width: 100%;
    }
  }
`;

const PostButton = styled(AddCalenderButton)`
  margin: ${({margin}) => margin ? '10px 0 0px 90px' : '15px 0 0px 80px'}
  float: left;
  cursor: pointer;
  text-transform: none;

  height: 40px;
  width: 107px;
  padding: 0;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
border-radius: 6px;
font-family: 'Rubik-medium';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
>img{
  margin-left:15px;
}


`;

const RsvpButton = styled(AddCalenderButton)`
  width: 176px;
  height: 45px;
  border-radius: 22px;
  background-color: #ef6827;
  font-size: 14px;
  margin-top: 0;
  @media(max-width: 1200px) {
    margin-top: ${({ details }) => details && '10px'};
  } 
`;

const LunchLink = styled.a`
  background-color: #159fc9;
  border: none;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 30px;
  padding: 7px 33px;
  float: left;
  
  &:focus, &:active, &:hover {
    color: white;
    outline: none;
  }
`;

const LinkContainer = styled.div`
  margin-top: 10px;
  float: left;
  width: 100%;
  
  a {
    color: white;
    text-align: center;
  }
`;
const IconContainer = styled.div`
width: unset !important;
position:absolute;
right: 4px;
bottom:4px;
`;

const InputImage = styled(ImageInput)`

padding: 10px 12px;
background-color: white;
border-radius: 0;
float: right;
margin-bottom: 0;
margin-top: ${({ noMarginTop }) => noMarginTop ? '0px' : '55px'}};
> img {
  color: #dddddd;
 width: 35px;
 height: 35px;
}
`;
const InputImage2 = styled.div`
margin-top:15px;
background-color: white;
border-radius: 0;
float: right;
margin-bottom: 0;
> img {
  color: #dddddd;
 width: 35px;
 height: 35px;
}
`;
const LayoutNew = styled.div`
  width: 100%;
  padding: 25px;
  .containerDiv{
    width: 100%;
    max-width: 1246px;
    // background: white;
    margin: auto;
  }
`;

const Container = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  max-width: 1246px;
  margin: auto;
`;

const ContainerFirst = styled.div`
   width:${({ width }) => width ? width : '63%'};
   margin-right:${({ width }) => width ? "0px" : '25px'};
   padding:${({ padding }) => padding ? padding : '0px'};
   .title{
    color: #005C87;
    font-family: Rubik-Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    display:flex;
    flex-wrap:wrap;
    padding-bottom:20px;
   }
`;

const ContainerSecond = styled.div`
  width:${({ width }) => width ? width : '37%'};

  .topLabelDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    margin-bottom: 28px;

    .label{
      display: flex;
      height: 48px;
      // padding: 12px 20px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      border-radius: 4px;
      color: #005C87;
      text-align: center;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .blueLabel{
      background: rgba(0, 92, 135, 0.10);
    }
    .redLabel{
      background: rgba(244, 170, 169, 0.15);
    }
  }
`;

const EventsTitle = styled.div`
  width: 100%;
  font-size:${({ font }) => font ? font : '24px'};
  line-height:24px;
  font-family: rubik-medium;
  color: #0D4270;
  margin-bottom: 25px;
`;

const EventDateContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  height: 80px;
  cursor:${({ cursor }) => cursor ? 'pointer' : ''};
  > div: first-child {
    width: 10%;
    display: flex;
    >img{
      width: auto;
      height: 60px;
      margin: auto;
      margin: 10px;
    }
  }
  > div: last-child {
    width: 90%;
    display: block;
    > div: first-child {
      width: 100%;
      display: flex;
      padding: 8px 10px;
      justify-content: space-between;
      > div: first-child {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0D4270;
      }
      .attendies{
        cursor: pointer;
      }
      > div: last-child {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #0D4270;
        color: ${({color }) => color? color : 'white'};

      }
    }
    > div: last-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 8px 10px;
      > div: first-child {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0D4270;

      }
  
      > div: last-child {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #0D4270;
        color: ${({ color }) => color ? color : 'white'};
 
      }
      .locationPlace{
        color: #0D4270;
      }
    }
  }
`;

const EventLocationContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  > div: first-child {
    width: 10.5%;
    max-width:80px;
    display: flex;
    >img{
      width: auto;
      height: 60px;
      margin: auto;
      margin: 15px 10px 15px 15px;
    }
  }
  > div: last-child {
    width: 89.5%;
    display: block;
    margin: auto;
    > div: first-child {
      width: 100%;
      display: flex;
      padding: 10px 15px 10px 10px;
      justify-content: space-between;
      > div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0D4270;

      }
      .location{
        display: flex;
        margin: auto;
        margin-left: 0px;
        font-family: rubik-medium;
      }
      > div: last-child {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: color: ${({ color }) => color ? 'rubik-medium' : 'rubik'};
        color: color: ${({ color }) => color ? color : 'white'};
   
      }
      .locationPlace{
        font-family: rubik-medium;
        color: #0D4270;
      }
    }
  }
`;

const EventsButton = styled.div`
  width: ${({ width }) => width ? '48%' : '100%'};
  font-size: 18px;
  line-height: 20px;
  font-family: rubik-medium;
  color: ${({ color }) => color ? color : 'white'};
  padding: 15px 10px 15px 10px;
  display: flex;
  align-items:center;
  margin-bottom: 15px;
  justify-content: center;
  border-radius: 4px;
  cursor: ${({ cursor }) => cursor ? '' : 'pointer'};
  background: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'white'};
  opacity: ${({ opacity }) => opacity ? opacity : ''};
  box-shadow: ${({ shadow }) => (shadow ? shadow : "0px 4px 11px rgba(105, 194, 255, 0.25)")};
  >a{
    text-decoration: none;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .point{
    font-size:18px;
    line-height:24px;
    color:#75D9A3;
    font-family: rubik-medium;
  }
`;

const AboutContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 6px;
  margin: ${({ marginTop }) => marginTop ? marginTop : '10px 0px 0px 0px'};
  > div: first-child {
     font-size: 18px;
     font-family: rubik-medium;
     padding: 25px 25px 15px 25px;
     color: ${({ colorValue }) => colorValue ? colorValue : '#0D4270'};
     display: flex;
     line-height: 24px;
  }
  > div: last-child {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: ${({ color }) => color ? color : '#3A3A3A'};
    display: flex;
    flex-wrap: wrap;
    padding: 0px 25px 25px 25px;
    >p{
      margin:0px;
    }
  }
`;

const CommentsContainerNew = styled.div`

  width: 100%;
  margin-top: 50px;
  max-width: 1246px;
  margin: auto;
  padding-right: 25px;
`;

const FormContainer = styled.form`
  width: ${({ editChallenge }) => editChallenge ? '100%' : '63%'};
  float: left;
  display: block;
  background-color: white;
  box-shadow: ${({ margin }) => margin ? '' : '1px 1.732px 46px rgba(0,0,0,0.18)'};
  margin-bottom: ${({ margin }) => margin ? '25px' : '0px'};
  margin-top: ${({ marginTop }) => marginTop ? '15px' : '0px'};
  border-radius: 6px;
  .calendar{
    display: flex;
    float: left;
  }
  
  > div > ul {
    margin-top: 20px;
  }
  
  @media only screen and (max-width: 767px) {
   width: 100%;
   margin-bottom: 20px;
   margin-top: 20px;
  }
   
    @media (max-width: 500px) {
      font-size: 20px;
      padding: 20px 10px 0 10px;
    }
  }
`;

const TopContainer=styled.div`
> h3 {
  
  font-family: 'Rubik-medium';

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0D4270;
  margin-bottom: 15px;
}
border-bottom: 1px solid rgba(156, 156, 156, 0.4);;
display:flex;
justify-content:space-between;
align-items:center;
width:100%;
height:74px;
padding:0 25px;
margin-bottom:20px;
>div{
  display:flex;
justify-content:center;
align-items:center;
}
>div img{
  width:40px;
  height:40px;
  margin-left:-12px;
  border-radius:50%
  border:2px solid #fff
}
>div >div{
  width:45px;
  height:40px;
  margin-left:-12px;
  border-radius:20px;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
border: 2px solid #FFFFFF;
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
color: white;
font-family: rubik-medium;
}
`



const Border = styled.div`
  width: ${({ size }) => size ? "100%": 'auto'};
  height: 1px;
  background: ${({ width, background }) => background ? background : width ? 'rgba(0, 92, 135, 0.10)' : 'rgba(0, 92, 135, 0.10)'};
  margin: auto 0px;
  margin: ${({ margin }) => margin ? margin : 'auto 0px'};
`;

const CustomTextAreaNew = styled.textarea`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  border-radius: 50px;
  border: none;
  background-color: #F8F8F8;
  width: ${({ width }) => width ? '93%' : '95%'};
  padding: 15px 20px;
  resize: none;
  height: ${({ height }) => height ? 'auto' : '50px'};
  overflow: hidden;
  padding-right: ${({ width }) => width ? '5px' : '0px'};
  &:active, &:focus {
    outline: none;
  }
  @media (max-width: 500px) {
    width: calc(100% - 50px);
    padding: 10px;
  }
`;

const CommentContentNew = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  border-radius: 50px;
  border: ${({ border }) => border ? '1px solid #9c9c9c' : '1px solid #9c9c9c'};
  background: #F8F8F8;
  > img {
    width: auto;
    height: 40px;
    margin-right: 20px;
    float: left;
    margin: auto 10px;
    display: flex;
    cursor: pointer;
    cursor: ${({ pointer }) => pointer ? '' : 'pointer'};
    margin-left: 0px;
    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  
  ${(props) => props.isReply && css`
    padding: 0 70px;
  `}

  .ImageDiv {
    position: relative;
    right: -82px;
    top: -52px;
    >img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      float: left;
      border-radius: 50%;
    }
  }

  .InputImageSection {
    float: left;
    left: 60px;
    top: -55px;
    padding: 0px;
    >img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      float: left;
    }
  }

  .InputImageSpan {
    color: #333339;
  }

  >div:nth-child(2){
    >div{
      width: 100%;
    }
  }
`;

const ProfileCount = styled.div`
.user-info{
  display: flex;
  margin-left: 0;
  padding-right: 15px;
  justify-content: flex-end;
  > h6 {
    color: #67676A;
    font-size: 15px
    font-family: Rubik-Medium;
    opacity: 0.5
    margin-left: 5px;
    text-transform: none;
    margin-top: 0;
  }
  > span {
    margin-left: -12px;
    margin-top: 4px;
    > img {
      width:45px;
      height: 45px;
      border-radius: 100%;
      border: 3px solid #fff;
      background: white;
    }
  }

.circle {
  font-family: Rubik;
  font-size: 12px;
  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  min-width: 45px;
  padding: 8px;
  text-align: center;
  background: linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%);
  border: 3px solid rgb(255, 255, 255); 
  border-radius: 50px;
}
`;

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div: first-child {
    width: 50%;
    >h3{
      font-size: 24px;
      font-family: rubik;
      color: #0D4270;
      display: flex;
      padding: 25px 10px;
      margin: 0px;
      padding-left: 15px;
    }
  }
  > div: last-child {
    width: 50%;
    margin: auto;
  }
`;

const FieldContainerNew = styled.div`
  padding: 0px;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 95%;
  border-bottom: ${({ border }) => border ? 'none' : '1px solid #e2e2e4'};
  position: relative; 
  ${({ noFloat }) => noFloat && css`
    padding: 34px 0 20px 0;
    @media (max-width: 500px) {
      padding: 0;
    }
  `}
  
  > span {
    font-size: 24px;
  }
  
  &:last-child {
    border-bottom: none;
  }
  
  ${(props) => props.fixHeight && css`
    border-bottom: none;
  `}

  ${({ socialPage }) => socialPage && css`
    > span {
      font-size: 18px;
      float: left;
      padding: 20px 0 10px;
    }
  `}

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

const PointValue = styled.div`
  width: 100%;
  display: flex;
  background: white;
  margin-bottom: 15px;
  border-radius: 6px;
  > div: first-child {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    font-family: rubik;
    color: ${({ color }) => color ? color : '#0D4270'};
    padding: 15px;
  }
   > div: last-child {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    font-family: rubik-medium;
    color: #0D4270;
    padding: 15px;
  }
`;

const EventsButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ChatScreenNew = styled.div`
  width: 100%;
  display: block;
  border: 1px solid #9c9c9c;
  background: #F8F8F8;
  border-radius: 20px;
  min-height: 268px;
  >img{
    width: auto;
    height: 40px;
  }
  > div: first-child {
    width: 100%;
  
  }
  > div: last-child {
    width: 100%;
    > div: first-child {
      width: 90%;
    }
    > div: last-child {
      width: auto;
      height: 40px;
    }
  }
`;

const InputImageNew = styled(ImageInput)`
  background-color: transparent;
  border-radius: 0;
  margin: auto;
  display: flex;
  padding: 15px 15px 0px 15px;
  > img {
    color: #dddddd;
    font-size: 25px;
    width: auto;
    height: 200px;
    border-radius: 6px;
  }
  >div{
    >img{
    width: auto;
    height: 24px;  
    position: relative;
    bottom: 8px;
    color: black;
    right: 15px;
    }
  }
`;

const IconContainerNew = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledTabContainer = styled(Tabs)`
   float: left;
  > ul {
    height: ${({newPadding}) => newPadding ? '70px' : 'none' };
    border-bottom: none;
    padding: ${({newPadding}) => newPadding ? '16px 10px 12px 18px' : '20px 20px 1px 33px' };
    font-size: 12px;
    text-transform: capitalize;
    color: rgb(153,153,153);
    background-color: #fff;
    
    > li {
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: ${BoldFont};
        margin-right: 5vw;
        text-transform: capitalize;
        padding: 5px 5px 17px 5px;
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
    
    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: rgb(21,159,202);
        border: none;
        border-bottom: 3px solid rgb(21,159,202);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid rgb(21,159,202);
          color: rgb(21,159,202);
        }
      }
    }
  }
`;

const StyledTabsNew = styled(StyledTabContainer)`
  width: 100%;
  .tab-content {
    position: relative;
  }
  
  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;
    
    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: -27px 41px 0 33px;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
        display: inline-block;
        float: right;
        margin: 0 41px 0 33px;
        padding-top: 10px;
    }
  }
  
  > ul {
    margin: 15px 0px;
    border-radius: 6px;
    padding: 10px 15px 10px 15px;
    @media (max-width: 600px) {
      min-height: 100px;
      margin-top: 30px;
      padding: 4px 0px;
    }
    > li {
      padding: 0px 30px;
      display: flex;
      justify-content: center;
      height: 30px;
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 15px;
        color: #CCCBDO;
        text-transform: none;
        font-family: ${BoldFont};
        margin-right: 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0
        margin: 0;
        padding: 10px 0px;

        font-family: 'Rubik-Regular';
        font-size: 16px;
        line-height: 20px;
        color:#9C9C9C;
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }

  li.first-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.second-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.third-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.first-tab.active {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 3px;
  }
  li.second-tab.active {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 3px;
  }
  li.third-tab.active {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 3px;
  }
  > ul > li > a{
    padding: 0px;

    font-family: 'Rubik-Regular';
    font-size: 18px;
    line-height: 21px;
    color:#9C9C9C;
  }
`;

const EventWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap:wrap;
  .timeLeft{
    background: rgba(156, 156, 156, 0.1);
    border-radius: 6px;
    height:40px;
    width:100%;
    margin: 5px 0 15px 0;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color:#FD7175;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const EventDateContainerV2 = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  height: 90px;
  cursor:${({ cursor }) => cursor ? 'pointer' : ''};
  > div: first-child {
    width: 10.5%;
    max-width:80px;
    display: flex;
    >img{
      width: auto;
      height: 60px;
      margin: auto;
      margin: 15px 5px 15px 15px;
    }
  }
  > div: last-child {
    width: 89.5%;
    display: block;
    margin: auto;
    margin-right: 5px;
    > div: first-child {
      width: 100%;
      display: flex;
      padding: 8px 10px;
      justify-content: space-between;
      > div: first-child {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0D4270;
      }
      .attendies{
        cursor: pointer;
      }
      > div: last-child {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #0D4270;
        color: ${({color }) => color? color : 'white'};

      }
    }
    > div: last-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 8px 10px;
      > div: first-child {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0D4270;

      }
  
      > div: last-child {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #0D4270;
        color: ${({ color }) => color ? color : 'white'};
 
      }
      .locationPlace{
        color: #0D4270;
      }
    }
  }
`;

const EventCompletePoint = styled.div`
  width: ${({ width }) => width ? '48%' : '100%'};
  background: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'white'};
  display:block;
  padding: 15px;
  margin-bottom: 15px;
  .wrapper{
    display:flex;
    font-size:18px;
    line-height:24px;
    color:#0D4270;
    font-family: rubik;
    width:100%;
    justify-content: center;
  }
    .text{
      font-size:18px;
      line-height:24px;
      color:#0D4270;
      font-family: rubik;
    }
    .point{
      font-size:18px;
      line-height:24px;
      // color:#75D9A3;
      font-family: rubik-medium;

      // background: #00B1BC;
      background:#76AB78 ;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const EventWrapperNewUI = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: ${ ({isRow}) => !isRow && "wrap"};
  flex-direction: ${ ({isRow}) => isRow && "column"}; 
  padding-bottom: ${ ({padding}) => padding?padding:"20px"}; 
  border-bottom: ${ ({border}) => border?border:"0"}; 
  .timeLeft{
    background: rgba(156, 156, 156, 0.1);
    border-radius: 6px;
    height:40px;
    width:100%;
    margin: 5px 0 15px 0;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color:#FD7175;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const EventsTitleNewUI = styled.div`
width: 100%;
font-size:${({ font }) => font ? font : '24px'};
line-height:28px;
font-family: rubik-medium;
color: #005C87;
font-size: 24px;
font-style: normal;
font-weight: 500;
height:56px;
align-items:center;
display: flex
line-height: 24px; /* 133.333% */
  ${'' /* margin-bottom: 25px; */}
`;

const EventImageContainerNewUI = styled(ImageContainer)`
  display: inline-block;
  // height: 484px;;
  width: 100%;
  margin-bottom: ${({margin}) => margin ? margin : '0'};
  @media (max-width: 767px) and (min-width: 530px) {
    height: 100%;
  }
  .eventLabel{
    height:0px;
    width:100%;
    position: relative;
    left: 10px;
    top: 10px;

    .label{
    height:24px;
    width:100px;
    display:flex;
    background:white;
    border-radius:3px;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color:#0D4270;
    position:relative;
    align-items: center;
    justify-content: center;
    top:10px;
    left:10px;
    }
  }
  .eventImageDiv{
    display: flex;
    width: 100%;
    height: 472px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-top: ${({isLunchAndLearn}) => isLunchAndLearn ? "0px" : '20px'};
    > img {
      width: 100%;
      height: 472px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid rgba(0, 122, 177, 0.30);
      background: lightgray 50% / cover no-repeat;
    }
  }
`;

const AboutEventDiv = styled.div`
    .aboutTitle{
      color: #005C87;
      font-family: Rubik-medium;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 12px;
    }
    .aboutDescription{
      width: 752px;
      color: #005C87;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
`;

const EventRightSectionRowMainDiv = styled.div`
  display: flex;
  flex-direction: row;
  color: #005C87;
  font-family: Rubik-medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  padding: 20px 0px;
  gap: 20px;
  border-top: 1px solid rgba(0, 92, 135, 0.10);
  // border-bottom: 1px solid rgba(0, 92, 135, 0.10);

  .imageDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;

    img{
      width: 24px;
      height: 24px;
    }
  }
  .details{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    justify-content: center;
    >div{
      .eye{
        width:40px;
        height:40px;
        border-radius: 4px;
        background: rgba(0, 122, 177, 0.05);
        display:flex;
        margin:auto 0 auto auto;
        align-items: center;
        justify-content: center;
        cursor:pointer;
      }
    }
    .attendesSectionMainDiv{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .attendeeDiv{
        display: flex;
        flex-direction: row;
        gap: 16px;
        span{
          display: flex;
          width: 40px;
          height: 40px;
          padding: 8px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background-color: rgba(0, 92, 135, 0.05);
          cursor: pointer;
        }
      }
    }
  }
  .eventFlyer{
    align-items: end;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    .iconButton{
      display: flex;
      ${'' /* width: 40px; */}
      height: 40px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: rgba(0, 92, 135, 0.05);
      cursor: pointer;
    }
  }
`;

const CoachProfileContainer = styled.div`
border-radius: 4px;
background: #FFF;
width: 1248px;
min-height: 280px;
flex-shrink: 0;
padding: 20px;

.coachProfile{
  display: flex;
  justify-content: space-between;
  ${'' /* align-items: center; */}
}
.coachProfileImageContainer{
  display: flex;
  align-items: center;
  width:calc(100% - 120px);
}

.coachProfileImage{
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.coachProfileName{
  color: #005C87;
font-family: Rubik-Medium;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 28px; 
}
.coachProfileEducation{
  color:  #005C87;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px;
margin:12px 0px;
display:flex;
}

.coachInterest{
  display: flex;
  align-items: center;
  flex-wrap:wrap;
}

.coachInterestTag{
  margin-right: 12px;
  border-radius: 24px;
background: #76AB78;
color: #FFF;
text-align: center;
font-family: Rubik-Regular;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 12px; 
padding: 5px 10px;
text-transform:capitalize;
margin-bottom:16px;
}

.coachProfileBio{
  color:  #005C87;
font-family: Rubik-Regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
margin:20px 0px;
}


.viewProfile{
  color:  #007AB1;
text-align: center;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; 
text-decoration-line: underline;
cursor: pointer;
}

`;

export {
  EventDetailsContainer,InputImage2, EventDetailsContent, ContentWrapper, CommentsContainer, FieldContainer, CustomTextArea, CommentContent, PostButton,
  EventDetailsContentContainer, EventDescription, EventDateDetails, RsvpButton, LunchLink, LinkContainer, EventImageContainer, IconContainer,
  InputImage, LayoutNew, Container, ContainerFirst, ContainerSecond, EventsTitle, EventDateContainer, EventLocationContainer, EventsButton,
  AboutContainer, CommentsContainerNew, FormContainer, Border, CustomTextAreaNew, CommentContentNew, ProfileCount, CardWrapper, FieldContainerNew,
  PointValue, EventsButtonWrapper, ChatScreenNew,CommentInnerContainer, InputImageNew, IconContainerNew, StyledTabsNew, EventWrapper, EventDateContainerV2,InputFieldContainer,TopContainer, EventCompletePoint, EventWrapperNewUI, EventsTitleNewUI, EventImageContainerNewUI, AboutEventDiv, EventRightSectionRowMainDiv, CoachProfileContainer
};
